export default class MemberModel {
  constructor(){
    this.objRaw = {};
    this.id = '';
    this.name = '';
    this.role = 'USER';
    this.email = '';
    this.imageList = [];
    this.image = '';
    this.loginStatus = '';
    this.createdAt = '';
    this.updatedAt = '';
    this.lastLoginAt = '';

    this.password = '';
    this.repeatPassword = '';
    this.rsaPasswordData = {
      password : '',
      repeatPassword : '',
    }
  }
  setData(obj){
    if( obj ) this.objRaw = obj;
    const { id, name, role, email, image, loginStatus, createdAt, updatedAt, lastLoginAt } = obj;
    this.id = id;
    this.name = name;
    this.role = role;
    this.email = email;
    if(image){
      this.imageList = [
        {
          src : image,
        }
      ]
    }
    this.loginStatus = loginStatus;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.lastLoginAt = lastLoginAt;
  }
  getRegisterData(){
    let obj = {
      role : this.role,
      email : this.email,
      name : this.name,
      password : this.rsaPasswordData.password,
      repeatPassword : this.rsaPasswordData.repeatPassword,
      image : this.imageList.length > 0 ? this.imageList[0].src : '',
    }
    return obj;
  }
  getModifyData(){
    let obj = {
      role : this.role,
      name : this.name,
      image : this.imageList.length > 0 ? this.imageList[0].src : '',
    }
    return obj;
  }
}